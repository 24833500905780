<template>
    <q-dialog v-model="openDialog">
        <q-card  style="width: 800px; max-width: 80vw;">
            <q-card-section class="row items-center ">
                 <div v-if="id === null" class="text-h6">Создать</div>
                <div v-else class="text-h6">Изменить</div>
                <q-space />
                <q-btn icon="close" flat round dense @click="$emit('close')" />
            </q-card-section>
                <q-form
                    @submit="onSubmit"
                    class="q-gutter-md"
                >
                    <div class="q-pa-md">
                        <div class="column">
                            <q-input outlined v-model="model.name" label="Имя" dense required hint=""/>
                            <q-input outlined type="textarea" v-model="model.text" label="Текст" dense required hint=""/>
                            <q-input v-if="showParam" outlined v-model="model.param" label="Параметр" dense hint=""/>
                            <div class="row q-col-gutter-x-md">
                               <q-select outlined v-model="model.type" :options="options.types" label="Тип" class="col-grow col-md-6" dense :rules="[typeRule]"/>
                                <q-select outlined v-model="model.status" :options="options.stats" label="Статус" class="col-grow col-md-6" dense :rules="[typeRule]"/>
                            </div>
                            <q-input outlined v-model="model.info" label="Инфо" dense hint=""/>
                        </div>
                      
                    </div>
                    <q-card-section class="q-mt-none">
                        <q-btn type="submit" class="bg-primary text-white" label="Сохранить" />
                        <q-btn v-if="this.id != null" class="bg-red-5 text-white float-right" label="Удалить" @click="onDelete" />
                    </q-card-section>
                </q-form>
            </q-card>
        </q-dialog>
</template>

<script>

export default {
    props:['openDialog'],
    created(){
        this.$store.commit('tpl_documents/SET_EMPTY_ITEM')
    },
    data () {
        return {

        }
    },
    computed:{
        model(){
            return this.$store.state.tpl_documents.item
        },
        id(){
            return this.$store.state.tpl_documents.updateId
        },
        options(){
            return this.$store.state.tpl_documents.options
        },
        showParam(){
            if(this.model.type === null) return true
            if(this.model.type.id == 1) return true
            return false
        }
    },
    methods:{
        onDelete(){
            this.$store.dispatch('tpl_documents/deleteItem', this.id)
            this.$emit('close')
        },
        onSubmit(){
            if(this.id != null){
                this.$store.dispatch('tpl_documents/updateItem', {'id': this.id, 'item': this.model})
            }else{
                this.$store.dispatch('tpl_documents/addItem', this.model)
            }
            this.$emit('close')
        },
          typeRule (val) {
            if (val === null) {
                return ''        
            }
        }
       
    }
    
}
</script>