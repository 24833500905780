<template>
    <div>
        <table-tpl-documents/>
    </div>
</template>

<script>
import TableTplDocuments from '@/components/templates/documents/table.vue'
export default {
  components: { TableTplDocuments },
    data () {
        return {
           
        }
    }
    
}
</script>