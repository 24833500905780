<template>
    <div class="q-pa-md">
        <q-table
        title="Документы"
        :data="data"
        :columns="columns"
        row-key="id"
        no-data-label="Нет данных"
        no-results-label="Нет данных"
        @row-click="onRowClick"
        :rows-per-page-options="[20]"
        table-header-class="bg-grey-2"
        >
             <template v-slot:top-right>
                <q-btn unelevated no-caps :color="showSearch ? 'grey-4' : 'grey-1'" text-color="grey-8" icon="search" label="Поиск" @click="showSearch =! showSearch"/>
                <q-btn unelevated no-caps color="grey-1" text-color="green-9" icon="add" label="Создать" @click="onCreate"/>
            </template>
             <template v-if="showSearch" v-slot:top-row>
                <q-tr>
                <q-td colspan="100%">
                    Тут будет поиск
                </q-td>
                </q-tr>
            </template>
            <template v-slot:header-cell="props">
                <q-th :props="props">
                    <strong>{{ props.col.label }}</strong>
                </q-th>
            </template>
            <template v-slot:body-cell-status="props">
                <q-td :props="props">
                     <q-badge :color="statusColor(props.value)" :label="props.value.label" style="padding:4px 8px" class=""/>
                </q-td>
            </template>
           
        </q-table>
        <modal-form :openDialog="openDialog" @close="openDialog = false"/>
    </div>
</template>

<script>
import ModalForm from './form.vue'

export default {
    components: { ModalForm },
    created(){
        this.$store.dispatch('tpl_documents/fetchData')
    },
    data () {
        return {
            openDialog: false,
            columns: [
                { name: 'name', required: true, label: 'Имя', align: 'left', field: 'name', sortable: true },
                // { name: 'param', align: 'left', label: 'Параметр', field: 'param', sortable: true },
                { name: 'type', align: 'left', label: 'Тип', field: row => row.type.label, sortable: true },
                { name: 'status',  align: 'left', label: 'Статус', field: 'status', sortable: true},
            ],
            showSearch:false
        }
    },
    computed:{
        data(){
            return this.$store.state.tpl_documents.data
        },
        statusColor(){
            return val => val.id === 1 ? 'secondary' : 'red-5'
        },

    },
    methods: {
        onRowClick(evt, row){
            this.$router.push('/tpl-documents/'+row.id)
        },
        onCreate(){
            // this.$router.push('/tpl-documents/create')
            //  this.$store.commit('catalog_stats/SET_EMPTY_ITEM')
            this.openDialog = true
        },
    },
    
}
</script>

<style scoped>
    /* td:first-child{
        background-color: #f5f5dc
    } */
</style>